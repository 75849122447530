<template>
  <div style="overflow: hidden !important;">
    <NavBarFreeMain />
    <!-- <BarVideoMain /> -->
    <MenuGolStats
      @scrollItem="scrollToElement"
      :sectionScroll="sectionScroll"
      :datosGS="true"
      :videosGS="false"
      :mejoresGS="true"
      :apostadorGS="true"
      :equiposGS="false"
      :previasGS="true"
      :tablaGS="true"
      :liveGS="true"
      :linkApostador="true"
      layoutType="main"
    />
    <div v-if="colorsConfig.show_logo" class="bet-logo">
      <BetLogo :url-logo="colorsConfig.main_logo" :url-link="colorsConfig.url_base" />
    </div>
    <div class="landing-tops is-centered">
      <div class="landing-tops__container">
        <div ref="datosGS">
          <GolstatsData
            :custom="colorsConfig"
            @season-selected="seasonSelected"
            @tournament-selected="tournamentSelected"
            @seasons-selected="onSeasonsSelected"
          />
        </div>
        <div ref="mejoresGS">
          <Tops :season="seasonUpdate" :custom="colorsConfig" />
        </div>
        <div v-if="colorsConfig.show_logo" class="bet-logo">
          <BetLogo :url-logo="colorsConfig.main_logo" :url-link="colorsConfig.url_base" />
        </div>
        <div id="Calendario" class="landing-tops__section-1-desktop" v-observe-visibility="visibilityChanged">
          <div ref="previasGS">
            <Calendar
              type="onlycomponent"
              :custom="colorsConfig"
              :seasons="seasonsSelectedForCalendar"
              :await-for-seasons="awaitForSeasonOrSeasons"
              is-full
              @tournament-selected="onTournamentSelected"
            />
          </div>
        </div>

        <div class="landing-tops__section-table-only-pc is-centered">
          <div id="Tabla-general" class="landing-tops__section-table-only-pc__container-table">
            <div ref="tablaGS">
              <div ref="tablaGS2">
                <Table
                  :season="seasonUpdate"
                  :custom="colorsConfig"
                  :await-for-season="awaitForSeasonOrSeasons"
                  @data-fetched="onTableDataFetched"
                />
              </div>
            </div>
          </div>
        </div>
        <div v-if="colorsConfig.show_logo" class="bet-logo">
          <BetLogo :url-logo="colorsConfig.main_logo" :url-link="colorsConfig.url_base" />
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import NavBarFreeMain from '@/components/Sections/Main/NavBarFreeMain';
import Footer from '@/components/Sections/Main/Footer';
import Tops from '@/views/Tops';
import GolstatsData from '@/views/GolstatsData';
import MenuGolStats from '@/views/MenuGolstats';
import Table from '@/components/Sections/Table/TableComponent';
import Calendar from '@/components/Sections/Calendar/CalendarComponent';
import axios from 'axios';
import { fetchColorsConfigByTeam } from '@/utils/api/profile';

export default {
  name: 'Landing',
  components: {
    Tops,
    Table,
    Calendar,
    Footer,
    NavBarFreeMain,
    GolstatsData,
    MenuGolStats,
    BetLogo: () => import('@/components/Elements/BetLogo'),
  },
  data() {
    return {
      isMovil: false,
      season: null,
      tournament: null,
      tips: '/tips',
      sectionScroll: 'datosGS',
      url: 'https://2dp6unm5we.execute-api.us-west-2.amazonaws.com/prod/custom_views/',
      colorsConfig: {
        logo: 'http://golstatsimages.blob.core.windows.net/content/escudo_golstats.png',
        color: '#cbee6b',
        background: '#242424',
        colorheader: '#132839',
        backgroundheader: '#CCE8B5',

        headerBackground: '#132839',
        headerColor: '#CBEE6E',
        headerBorderColor: '#CBEE6E',

        header2Background: '#132839',
        header2Color: '#FFFFFF',

        subHeaderBackground: '#cbee6b',
        subHeaderColor: '#353535',

        //bettool
        headerBetToolBackground: '#242424',
        subheaderBetToolBackground: '#151515',

        bet_house: '',
        main_logo: '',
        secondary_logo: '',
        show_logo: false,
        url_base: '',

        type: 1,
        team_id: 0,
        categories: 'all',
      },
      seasonsSelectedForCalendar: [],
      seasonsSelectedForRightBet: [],
    };
  },
  computed: {
    ...mapState('loginGeneral', ['user']),
    ...mapState('general', ['sectionRef']),
    ...mapGetters('loginGeneral', ['isLoggedIn']),
    seasonUpdate() {
      return this.season;
    },
    tournamentUpdate() {
      return this.tournament;
    },
    awaitForSeasonOrSeasons() {
      if (this.colorsConfig.tournaments === undefined) {
        return true;
      }
      return this.colorsConfig.tournaments;
    },
  },
  watch: {
    sectionRef(newValue) {
      this.scrollToElement(newValue);
    },
  },
  methods: {
    ...mapMutations('auth', ['SET_AUTH_COMPONENT']),
    ...mapMutations('profileGeneral', ['setColorsConfig']),
    async fillBetLogos() {
      try {
        const colorsConfig = await fetchColorsConfigByTeam();
        this.colorsConfig.bet_house = colorsConfig.bet_house;
        this.colorsConfig.main_logo = colorsConfig.main_logo;
        this.colorsConfig.secondary_logo = colorsConfig.secondary_logo;
        this.colorsConfig.show_logo = colorsConfig.show_logo;
        this.colorsConfig.url_base = colorsConfig.url_base;
        this.setColorsConfig(colorsConfig);
      } catch (e) {
        throw new Error(e);
      }
    },
    scrollToElement(val) {
      if (val == 'apostadorGS') {
        let routeData = this.$router.resolve({ name: 'goltipster' });
        window.open(routeData.href, '_blank');
      } else {
        var el = this.$refs[val];
        const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
        if (vw < 1080 && val == 'tablaGS') {
          el = this.$refs['tablaGS2'];
        }
        if (el) {
          el.scrollIntoView({ behavior: 'smooth' });
        }
      }
    },
    ...mapMutations(['SET_PREMIUM_MESSAGE']),
    ...mapMutations('general', ['setTournamentSelected']),
    onPremiumDisabledClicked() {
      this.SET_PREMIUM_MESSAGE(true);
    },
    seasonSelected($valor) {
      this.season = $valor;
    },
    tournamentSelected(tournament) {
      this.tournament = tournament.id;
      this.setTournamentSelected(tournament);
    },
    /**
     * @author Alejandro Martinez
     * @param {Array<{id: number, type: number}>} seasonsSelected
     */
    onSeasonsSelected(seasonsSelected) {
      this.seasonsSelectedForCalendar = seasonsSelected;
      this.seasonsSelectedForRightBet = seasonsSelected;
    },
    onTournamentSelected(tournament) {
      this.setTournamentSelected(tournament);
    },
    handleScrolling(event) {
      if (this.$refs['datosGS']) {
        let sizeDatosGS = this.$refs['datosGS'].offsetTop + this.$refs['datosGS'].offsetHeight;
        let scrollCalc = event.target.scrollTop + 136;
        if (scrollCalc >= this.$refs['datosGS'].offsetTop && scrollCalc <= sizeDatosGS) {
          this.sectionScroll = 'datosGS';
        }
      }
      if (this.$refs['previasGS']) {
        let sizePreviasGS = this.$refs['previasGS'].offsetTop + this.$refs['previasGS'].offsetHeight;
        let scrollCalc = event.target.scrollTop + 136;
        if (scrollCalc >= this.$refs['previasGS'].offsetTop && scrollCalc <= sizePreviasGS) {
          this.sectionScroll = 'previasGS';
        }
      }
      const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
      if (vw < 1080) {
        if (this.$refs['tablaGS2']) {
          let sizeTablaGS = this.$refs['tablaGS2'].offsetTop + this.$refs['tablaGS2'].offsetHeight;
          let scrollCalc = event.target.scrollTop + 136;
          if (scrollCalc >= this.$refs['tablaGS2'].offsetTop && scrollCalc <= sizeTablaGS) {
            this.sectionScroll = 'tablaGS';
          }
        }
      } else {
        if (this.$refs['tablaGS']) {
          let sizeTablaGS = this.$refs['tablaGS'].offsetTop + this.$refs['tablaGS'].offsetHeight;
          let scrollCalc = event.target.scrollTop + 136;
          if (scrollCalc >= this.$refs['tablaGS'].offsetTop && scrollCalc <= sizeTablaGS) {
            this.sectionScroll = 'tablaGS';
          }
        }
      }
      if (this.$refs['mejoresGS']) {
        let sizeMejoresGS = this.$refs['mejoresGS'].offsetTop + this.$refs['mejoresGS'].offsetHeight;
        let scrollCalc = event.target.scrollTop + 136;
        if (scrollCalc >= this.$refs['mejoresGS'].offsetTop && scrollCalc <= sizeMejoresGS) {
          this.sectionScroll = 'mejoresGS';
        }
      }
    },
    async sendLog(logType) {
      try {
        if (this.$route.name !== 'gana') {
          return;
        }
        const logsUrl = 'https://95ow8l4hkh.execute-api.us-east-2.amazonaws.com/prod/visitlogs';
        const body = {
          name: 'gana',
          ref: document.referrer || 'home',
          type: logType,
          user_type: this.user.premiumAccount ? 2 : 1,
        };
        await axios.post(logsUrl, body);
      } catch (e) {
        throw new Error(e);
      }
    },
    onTableDataFetched() {
      this.sendLog(2);
    },
  },
  mounted() {
    setTimeout(() => {
      if (this.$route.hash === '#betTool') {
        const betTool = document.getElementById('betTool');
        if (betTool) {
          betTool.scrollIntoView();
        }
      }
    }, 100);
    if (this.$route.query.d && this.$route.query.d === '1') {
      this.SET_AUTH_COMPONENT('SelectPlan');
    }
  },
  created() {
    this.sendLog(1);
    this.fillBetLogos();
  },
  beforeMount() {
    window.addEventListener('scroll', this.handleScrolling, true);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScrolling);
  },
};
</script>
<style>
html > body {
  padding-top: 0 !important;
}
.is-fullheight-with-navbar-and-footer,
.is-fullheight-with-navbar-and-footer-mobile {
  height: calc(100% - 1px);
}
</style>
<style lang="scss" scoped>
* {
  scroll-margin-top: 128px;
  @media screen and (max-width: 634px) {
    scroll-margin-top: 118px;
  }
}
.margin-none {
  margin-top: 25px !important;
}
.landing-tops {
  display: flex;
  justify-content: center;
  margin-bottom: 0px !important;
  &__section-only-bets {
    margin-top: 30px;
  }
  &__container {
    width: 100%;
    max-width: 1268px;
    height: auto;
  }
  &__section-table-only-pc {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    margin-top: 80px;
    &__container-table {
      width: 100%;
      max-width: 618px;
      height: auto;
    }
    @media screen and (max-width: 1080px) {
      display: flex;
    }
  }

  &__section-1 {
    display: grid;
    grid-template-columns: 50% 50%;
    margin-bottom: 40px;
    margin-top: 40px;
    &__calendar {
      margin-right: 5px;
      @media screen and (max-width: 1080px) {
        margin-right: 0px;
      }
    }
    &__table {
      margin-left: 5px;
      max-height: 700px;
      @media screen and (max-width: 1080px) {
        display: none;
      }
    }
    @media screen and (max-width: 1080px) {
      grid-template-columns: 100%;
      margin-bottom: 0px;
      margin-top: 0px;
    }
  }
  &__section-1-type4 {
    display: grid;
    grid-template-columns: 50% 50%;
    margin-bottom: 40px;
    margin-top: 40px;
    &__calendar {
      margin-left: 5px;
      @media screen and (max-width: 1080px) {
        margin-right: 0px;
      }
    }
    &__table {
      margin-right: 5px;
      max-height: 700px;
      @media screen and (max-width: 1080px) {
        display: none;
      }
    }
    @media screen and (max-width: 1080px) {
      grid-template-columns: 100%;
      margin-bottom: 0px;
      margin-top: 0px;
    }
  }
  &__section-1-desktop {
    display: grid;
    grid-template-columns: 100%;
    margin-bottom: 80px;
    margin-top: 40px;
  }
  &__section-1-desktop-2 {
    display: grid;
    grid-template-columns: 100%;
    margin-bottom: 80px;
    margin-top: 40px;
    @media screen and (max-width: 1080px) {
      margin-bottom: 0px;
      margin-top: 0px;
    }
  }
  &__section-calendar-only {
    display: flex;
    justify-content: center;
    margin-bottom: 0px;
    display: none;
    &__container-calendar {
      width: 100%;
      max-width: 1268px;
      height: auto;
    }
    @media screen and (max-width: 1080px) {
      display: flex;
    }
  }
  &__section-table-only {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    margin-top: 80px;
    display: none;
    &__container-table {
      width: 100%;
      max-width: 618px;
      height: auto;
    }
    @media screen and (max-width: 1080px) {
      display: flex;
    }
  }
  &__section-table-only-desktop {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    margin-top: 40px;
    &__container-table {
      width: 100%;
      max-width: 618px;
      height: auto;
    }
  }
}

.bet-logo {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 1em 0 0.6em;
}
</style>
