<template>
  <div
    :class="{
      'container-wrapper-menu-golstats-flag': fixedMovil == 1,
      'container-wrapper-menu-golstats-movil-flag': fixedMovil == 2,
    }"
  >
    <div :class="{ fixed: fixedOrderPanel }">
      <div
        :class="{
          'container-wrapper-menu-golstats': fixedOrderPanel && fixedMovil == 1,
          'container-wrapper-menu-golstats-movil': fixedOrderPanel && fixedMovil == 2,
        }"
      >
        <div class="menu-golstats">
          <div class="menu-golstats-pc-indicator">
            <template v-if="layoutType == 'goltipster'">
              <div class="menu-golstats__item-menu" @click="scrollToElement('apostadorGS')" v-if="apostadorGS">
                <div class="menu-golstats__item-menu__icon">
                  <img class="menu-golstats__item-menu__icon__apostadores" src="/images/menu/icn-apostadores@2x.png" />
                </div>
                <div class="menu-golstats__item-menu__title">
                  <span class="menu-golstats__item-menu__title__bold"
                    >Apostador<br />
                    <span class="picks-text" v-if="isPremiumUser">Picks</span
                    ><span v-else class="picks-text">Free Picks</span></span
                  >
                </div>
                <div class="menu-golstats__item-menu__indicador" v-if="optionMenuSelected == 'apostadorGS'">_</div>
              </div>
              <div class="menu-golstats__item-menu-separador" v-if="apostadorGS">
                <div class="menu-golstats__item-menu-separador__line"></div>
              </div>
              <div class="menu-golstats__item-menu" @click="scrollToElement('testimoniosGS')" v-if="testimoniosGS">
                <div class="menu-golstats__item-menu__icon">
                  <img class="menu-golstats__item-menu__icon__apostadores" src="/images/menu/icn_testimonios@2x.png" />
                </div>
                <div class="menu-golstats__item-menu__title">
                  <span class="menu-golstats__item-menu__title__bold">Testimonios</span>
                </div>
                <div class="menu-golstats__item-menu__indicador" v-if="optionMenuSelected == 'testimoniosGS'">_</div>
              </div>
              <div class="menu-golstats__item-menu-separador" v-if="testimoniosGS">
                <div class="menu-golstats__item-menu-separador__line"></div>
              </div>
              <div class="menu-golstats__item-menu" @click="scrollToElement('datosGS')" v-if="datosGS">
                <div class="menu-golstats__item-menu__icon">
                  <img class="menu-golstats__item-menu__icon__datos" src="/images/menu/icn-datos-gs@2x.png" />
                </div>
                <div class="menu-golstats__item-menu__title">
                  <span class="menu-golstats__item-menu__title__bold">Datos</span> <br />GolStats
                </div>
                <div class="menu-golstats__item-menu__indicador" v-if="optionMenuSelected == 'datosGS'">_</div>
              </div>
              <div class="menu-golstats__item-menu-separador" v-if="datosGS">
                <div class="menu-golstats__item-menu-separador__line"></div>
              </div>
              <div class="menu-golstats__item-menu" @click="scrollToElement('videosGS')" v-if="videosGS">
                <div class="menu-golstats__item-menu__icon">
                  <img class="menu-golstats__item-menu__icon__videos" src="/images/menu/icn-videos@2x.png" />
                </div>
                <div class="menu-golstats__item-menu__title">
                  <span class="menu-golstats__item-menu__title__bold">Videos</span>
                </div>
                <div class="menu-golstats__item-menu__indicador" v-if="optionMenuSelected == 'videosGS'">_</div>
              </div>
              <div class="menu-golstats__item-menu-separador" v-if="videosGS">
                <div class="menu-golstats__item-menu-separador__line"></div>
              </div>

              <div class="menu-golstats__item-menu" @click="scrollToElement('mejoresGS')" v-if="mejoresGS">
                <div class="menu-golstats__item-menu__icon">
                  <img
                    class="menu-golstats__item-menu__icon__jugadores"
                    src="/images/menu/icn-mejores-jugadores@2x.png"
                  />
                </div>
                <div class="menu-golstats__item-menu__title">
                  <span class="menu-golstats__item-menu__title__bold">Mejores</span><br />
                  Jugadores
                </div>
                <div class="menu-golstats__item-menu__indicador" v-if="optionMenuSelected == 'mejoresGS'">_</div>
              </div>
              <div class="menu-golstats__item-menu-separador" v-if="mejoresGS">
                <div class="menu-golstats__item-menu-separador__line"></div>
              </div>

              <div class="menu-golstats__item-menu" @click="scrollToElement('equiposGS')" v-if="equiposGS">
                <div class="menu-golstats__item-menu__icon">
                  <img
                    class="menu-golstats__item-menu__icon__equipos"
                    src="/images/menu/icn-equipos-jugadores@2x.png"
                  />
                </div>
                <div class="menu-golstats__item-menu__title">
                  <span class="menu-golstats__item-menu__title__bold">Equipos /</span> <br />Jugadores
                </div>
                <div class="menu-golstats__item-menu__indicador" v-if="optionMenuSelected == 'equiposGS'">_</div>
              </div>
              <div class="menu-golstats__item-menu-separador" v-if="equiposGS">
                <div class="menu-golstats__item-menu-separador__line"></div>
              </div>
              <div class="menu-golstats__item-menu" @click="scrollToElement('previasGS')" v-if="previasGS">
                <div class="menu-golstats__item-menu__icon">
                  <img class="menu-golstats__item-menu__icon__previas" src="/images/menu/icn-previas@2x.png" />
                </div>
                <div class="menu-golstats__item-menu__title">
                  <span class="menu-golstats__item-menu__title__bold">Calendario</span>
                </div>
                <div class="menu-golstats__item-menu__indicador" v-if="optionMenuSelected == 'previasGS'">_</div>
              </div>
              <div class="menu-golstats__item-menu-separador" v-if="previasGS">
                <div class="menu-golstats__item-menu-separador__line"></div>
              </div>
              <div
                class="menu-golstats__item-menu"
                :class="!showLive ? 'item-menu-live' : ''"
                v-if="liveGS"
                @click="openLive"
              >
                <div class="menu-golstats__item-menu__icon">
                  <img
                    :class="
                      showLive ? 'menu-golstats__item-menu__icon__live' : 'menu-golstats__item-menu__icon__live-none'
                    "
                    src="/images/menu/icn_live.svg"
                  />
                </div>
                <div class="menu-golstats__item-menu__title">
                  <span class="menu-golstats__item-menu__title__bold">LIVE</span>
                </div>
                <div class="menu-golstats__item-menu__indicador" v-if="optionMenuSelected == 'liveGS'">_</div>
              </div>
              <div class="menu-golstats__item-menu-separador" v-if="tablaGS">
                <div class="menu-golstats__item-menu-separador__line"></div>
              </div>
              <div class="menu-golstats__item-menu" @click="scrollToElement('tablaGS')" v-if="tablaGS">
                <div class="menu-golstats__item-menu__icon">
                  <img class="menu-golstats__item-menu__icon__tabla" src="/images/menu/icn-tabla-general@2x.png" />
                </div>
                <div class="menu-golstats__item-menu__title">
                  Tabla <br /><span class="menu-golstats__item-menu__title__bold">General</span>
                </div>
                <div class="menu-golstats__item-menu__indicador" v-if="optionMenuSelected == 'tablaGS'">_</div>
              </div>
            </template>
            <template v-else>
              <template v-if="layoutType == 'main'">
                <div class="menu-golstats__item-menu" @click="scrollToElement('datosGS')" v-if="datosGS">
                  <div class="menu-golstats__item-menu__icon">
                    <img class="menu-golstats__item-menu__icon__datos" src="/images/menu/icn-datos-gs@2x.png" />
                  </div>
                  <div class="menu-golstats__item-menu__title">
                    <span class="menu-golstats__item-menu__title__bold">Datos</span> <br />GolStats
                  </div>
                  <div class="menu-golstats__item-menu__indicador" v-if="optionMenuSelected == 'datosGS'">_</div>
                </div>
                <div class="menu-golstats__item-menu-separador" v-if="datosGS">
                  <div class="menu-golstats__item-menu-separador__line"></div>
                </div>
                <div class="menu-golstats__item-menu" @click="scrollToElement('videosGS')" v-if="videosGS">
                  <div class="menu-golstats__item-menu__icon">
                    <img class="menu-golstats__item-menu__icon__videos" src="/images/menu/icn-videos@2x.png" />
                  </div>
                  <div class="menu-golstats__item-menu__title">
                    <span class="menu-golstats__item-menu__title__bold">Videos</span>
                  </div>
                  <div class="menu-golstats__item-menu__indicador" v-if="optionMenuSelected == 'videosGS'">_</div>
                </div>
                <div class="menu-golstats__item-menu-separador" v-if="videosGS">
                  <div class="menu-golstats__item-menu-separador__line"></div>
                </div>
                <div class="menu-golstats__item-menu" @click="scrollToElement('apostadorGS')" v-if="apostadorGS">
                  <div class="menu-golstats__item-menu__icon">
                    <img
                      class="menu-golstats__item-menu__icon__apostadores"
                      src="/images/menu/icn-apostadores@2x.png"
                    />
                  </div>
                  <div class="menu-golstats__item-menu__title">
                    <span class="menu-golstats__item-menu__title__bold"
                      >Apostador<br /><span class="picks-text" v-if="isPremiumUser">Picks</span
                      ><span v-else class="picks-text">Free Picks</span></span
                    >
                  </div>
                  <div class="menu-golstats__item-menu__indicador" v-if="optionMenuSelected == 'apostadorGS'">_</div>
                </div>
                <div class="menu-golstats__item-menu-separador" v-if="apostadorGS">
                  <div class="menu-golstats__item-menu-separador__line"></div>
                </div>
                <div class="menu-golstats__item-menu" @click="scrollToElement('mejoresGS')" v-if="mejoresGS">
                  <div class="menu-golstats__item-menu__icon">
                    <img
                      class="menu-golstats__item-menu__icon__jugadores"
                      src="/images/menu/icn-mejores-jugadores@2x.png"
                    />
                  </div>
                  <div class="menu-golstats__item-menu__title">
                    <span class="menu-golstats__item-menu__title__bold">Mejores</span><br />
                    Jugadores
                  </div>
                  <div class="menu-golstats__item-menu__indicador" v-if="optionMenuSelected == 'mejoresGS'">_</div>
                </div>
                <div class="menu-golstats__item-menu-separador" v-if="mejoresGS">
                  <div class="menu-golstats__item-menu-separador__line"></div>
                </div>

                <div class="menu-golstats__item-menu" @click="scrollToElement('equiposGS')" v-if="equiposGS">
                  <div class="menu-golstats__item-menu__icon">
                    <img
                      class="menu-golstats__item-menu__icon__equipos"
                      src="/images/menu/icn-equipos-jugadores@2x.png"
                    />
                  </div>
                  <div class="menu-golstats__item-menu__title">
                    <span class="menu-golstats__item-menu__title__bold">Equipos /</span> <br />Jugadores
                  </div>
                  <div class="menu-golstats__item-menu__indicador" v-if="optionMenuSelected == 'equiposGS'">_</div>
                </div>
                <div class="menu-golstats__item-menu-separador" v-if="equiposGS">
                  <div class="menu-golstats__item-menu-separador__line"></div>
                </div>
                <div class="menu-golstats__item-menu" @click="scrollToElement('previasGS')" v-if="previasGS">
                  <div class="menu-golstats__item-menu__icon">
                    <img class="menu-golstats__item-menu__icon__previas" src="/images/menu/icn-previas@2x.png" />
                  </div>
                  <div class="menu-golstats__item-menu__title">
                    <span class="menu-golstats__item-menu__title__bold">Calendario</span>
                  </div>
                  <div class="menu-golstats__item-menu__indicador" v-if="optionMenuSelected == 'previasGS'">_</div>
                </div>
                <div class="menu-golstats__item-menu-separador" v-if="previasGS">
                  <div class="menu-golstats__item-menu-separador__line"></div>
                </div>
                <div
                  class="menu-golstats__item-menu"
                  :class="!showLive ? 'item-menu-live' : ''"
                  v-if="liveGS"
                  @click="openLive"
                >
                  <div class="menu-golstats__item-menu__icon">
                    <img
                      :class="
                        showLive ? 'menu-golstats__item-menu__icon__live' : 'menu-golstats__item-menu__icon__live-none'
                      "
                      src="/images/menu/icn_live.svg"
                    />
                  </div>
                  <div class="menu-golstats__item-menu__title">
                    <span class="menu-golstats__item-menu__title__bold">LIVE</span>
                  </div>
                  <div class="menu-golstats__item-menu__indicador" v-if="optionMenuSelected == 'liveGS'">_</div>
                </div>
                <div class="menu-golstats__item-menu-separador" v-if="tablaGS">
                  <div class="menu-golstats__item-menu-separador__line"></div>
                </div>
                <div class="menu-golstats__item-menu" @click="scrollToElement('tablaGS')" v-if="tablaGS">
                  <div class="menu-golstats__item-menu__icon">
                    <img class="menu-golstats__item-menu__icon__tabla" src="/images/menu/icn-tabla-general@2x.png" />
                  </div>
                  <div class="menu-golstats__item-menu__title">
                    Tabla <br /><span class="menu-golstats__item-menu__title__bold">General</span>
                  </div>
                  <div class="menu-golstats__item-menu__indicador" v-if="optionMenuSelected == 'tablaGS'">_</div>
                </div>
              </template>
              <template v-else>
                <div class="menu-golstats__item-menu" @click="scrollToElement('datosGS')" v-if="datosGS">
                  <div class="menu-golstats__item-menu__icon">
                    <img class="menu-golstats__item-menu__icon__datos" src="/images/menu/icn-datos-gs@2x.png" />
                  </div>
                  <div class="menu-golstats__item-menu__title">
                    <span class="menu-golstats__item-menu__title__bold">Datos</span> <br />GolStats
                  </div>
                  <div class="menu-golstats__item-menu__indicador" v-if="optionMenuSelected == 'datosGS'">_</div>
                </div>
                <div class="menu-golstats__item-menu-separador" v-if="datosGS">
                  <div class="menu-golstats__item-menu-separador__line"></div>
                </div>
                <div class="menu-golstats__item-menu" @click="scrollToElement('videosGS')" v-if="videosGS">
                  <div class="menu-golstats__item-menu__icon">
                    <img class="menu-golstats__item-menu__icon__videos" src="/images/menu/icn-videos@2x.png" />
                  </div>
                  <div class="menu-golstats__item-menu__title">
                    <span class="menu-golstats__item-menu__title__bold">Videos</span>
                  </div>
                  <div class="menu-golstats__item-menu__indicador" v-if="optionMenuSelected == 'videosGS'">_</div>
                </div>
                <div class="menu-golstats__item-menu-separador" v-if="videosGS">
                  <div class="menu-golstats__item-menu-separador__line"></div>
                </div>
                <div class="menu-golstats__item-menu" @click="scrollToElement('apostadorGS')" v-if="apostadorGS">
                  <div class="menu-golstats__item-menu__icon">
                    <img
                      class="menu-golstats__item-menu__icon__apostadores"
                      src="/images/menu/icn-apostadores@2x.png"
                    />
                  </div>
                  <div class="menu-golstats__item-menu__title">
                    <span class="menu-golstats__item-menu__title__bold">Apostador</span>
                  </div>
                  <div class="menu-golstats__item-menu__indicador" v-if="optionMenuSelected == 'apostadorGS'">_</div>
                </div>
                <div class="menu-golstats__item-menu-separador" v-if="apostadorGS">
                  <div class="menu-golstats__item-menu-separador__line"></div>
                </div>
                <div class="menu-golstats__item-menu" @click="scrollToElement('mejoresGS')" v-if="mejoresGS">
                  <div class="menu-golstats__item-menu__icon">
                    <img
                      class="menu-golstats__item-menu__icon__jugadores"
                      src="/images/menu/icn-mejores-jugadores@2x.png"
                    />
                  </div>
                  <div class="menu-golstats__item-menu__title">
                    <span class="menu-golstats__item-menu__title__bold">Mejores</span><br />
                    Jugadores
                  </div>
                  <div class="menu-golstats__item-menu__indicador" v-if="optionMenuSelected == 'mejoresGS'">_</div>
                </div>
                <div class="menu-golstats__item-menu-separador" v-if="mejoresGS">
                  <div class="menu-golstats__item-menu-separador__line"></div>
                </div>

                <div class="menu-golstats__item-menu" @click="scrollToElement('equiposGS')" v-if="equiposGS">
                  <div class="menu-golstats__item-menu__icon">
                    <img
                      class="menu-golstats__item-menu__icon__equipos"
                      src="/images/menu/icn-equipos-jugadores@2x.png"
                    />
                  </div>
                  <div class="menu-golstats__item-menu__title">
                    <span class="menu-golstats__item-menu__title__bold">Equipos /</span> <br />Jugadores
                  </div>
                  <div class="menu-golstats__item-menu__indicador" v-if="optionMenuSelected == 'equiposGS'">_</div>
                </div>
                <div class="menu-golstats__item-menu-separador" v-if="equiposGS">
                  <div class="menu-golstats__item-menu-separador__line"></div>
                </div>
                <div class="menu-golstats__item-menu" @click="scrollToElement('previasGS')" v-if="previasGS">
                  <div class="menu-golstats__item-menu__icon">
                    <img class="menu-golstats__item-menu__icon__previas" src="/images/menu/icn-previas@2x.png" />
                  </div>
                  <div class="menu-golstats__item-menu__title">
                    <span class="menu-golstats__item-menu__title__bold">Calendario</span>
                  </div>
                  <div class="menu-golstats__item-menu__indicador" v-if="optionMenuSelected == 'previasGS'">_</div>
                </div>
                <div class="menu-golstats__item-menu-separador" v-if="previasGS">
                  <div class="menu-golstats__item-menu-separador__line"></div>
                </div>
                <div
                  class="menu-golstats__item-menu"
                  :class="!showLive ? 'item-menu-live' : ''"
                  v-if="liveGS"
                  @click="openLive"
                >
                  <div class="menu-golstats__item-menu__icon">
                    <img
                      :class="
                        showLive ? 'menu-golstats__item-menu__icon__live' : 'menu-golstats__item-menu__icon__live-none'
                      "
                      src="/images/menu/icn_live.svg"
                    />
                  </div>
                  <div class="menu-golstats__item-menu__title">
                    <span class="menu-golstats__item-menu__title__bold">LIVE</span>
                  </div>
                  <div class="menu-golstats__item-menu__indicador" v-if="optionMenuSelected == 'liveGS'">_</div>
                </div>
                <div class="menu-golstats__item-menu-separador" v-if="tablaGS">
                  <div class="menu-golstats__item-menu-separador__line"></div>
                </div>
                <div class="menu-golstats__item-menu" @click="scrollToElement('tablaGS')" v-if="tablaGS">
                  <div class="menu-golstats__item-menu__icon">
                    <img class="menu-golstats__item-menu__icon__tabla" src="/images/menu/icn-tabla-general@2x.png" />
                  </div>
                  <div class="menu-golstats__item-menu__title">
                    Tabla <br /><span class="menu-golstats__item-menu__title__bold">General</span>
                  </div>
                  <div class="menu-golstats__item-menu__indicador" v-if="optionMenuSelected == 'tablaGS'">_</div>
                </div>
              </template>
            </template>
          </div>

          <div class="menu-golstats-movil-indicator">
            <BCarousel
              :cycle="false"
              :continuous="false"
              :arrow="false"
              :pause-info="false"
              :autoplay="false"
              :arrow-hover="false"
            >
              <template v-if="layoutType == 'main'">
                <BCarouselItem>
                  <div class="menu-golstats">
                    <div class="menu-golstats__item-menu" @click="scrollToElement('datosGS')">
                      <div class="menu-golstats__item-menu__icon">
                        <img class="menu-golstats__item-menu__icon__datos" src="/images/menu/icn-datos-gs@2x.png" />
                      </div>
                      <div class="menu-golstats__item-menu__title">
                        <span class="menu-golstats__item-menu__title__bold">Datos</span> <br />GolStats
                      </div>
                      <div class="menu-golstats__item-menu__indicador" v-if="optionMenuSelected == 'datosGS'">_</div>
                    </div>
                    <div class="menu-golstats__item-menu-separador">
                      <div class="menu-golstats__item-menu-separador__line"></div>
                    </div>
                    <div class="menu-golstats__item-menu" @click="scrollToElement('apostadorGS')">
                      <div class="menu-golstats__item-menu__icon">
                        <img
                          class="menu-golstats__item-menu__icon__apostadores"
                          src="/images/menu/icn-apostadores@2x.png"
                        />
                      </div>
                      <div class="menu-golstats__item-menu__title">
                        <span class="menu-golstats__item-menu__title__bold"
                          >Apostador<br /><span class="picks-text" v-if="isPremiumUser">Picks</span
                          ><span v-else class="picks-text">Free Picks</span></span
                        >
                      </div>
                      <div class="menu-golstats__item-menu__indicador" v-if="optionMenuSelected == 'apostadorGS'">
                        _
                      </div>
                    </div>
                    <div class="menu-golstats__item-menu-separador">
                      <div class="menu-golstats__item-menu-separador__line"></div>
                    </div>
                    <div class="menu-golstats__item-menu" @click="scrollToElement('mejoresGS')">
                      <div class="menu-golstats__item-menu__icon">
                        <img
                          class="menu-golstats__item-menu__icon__jugadores"
                          src="/images/menu/icn-mejores-jugadores@2x.png"
                        />
                      </div>
                      <div class="menu-golstats__item-menu__title">
                        <span class="menu-golstats__item-menu__title__bold">Mejores</span><br />
                        Jugadores
                      </div>
                      <div class="menu-golstats__item-menu__indicador" v-if="optionMenuSelected == 'mejoresGS'">_</div>
                    </div>
                    <div class="menu-golstats__item-menu-separador">
                      <div class="menu-golstats__item-menu-separador__line"></div>
                    </div>
                    <div class="menu-golstats__item-menu" @click="scrollToElement('previasGS')">
                      <div class="menu-golstats__item-menu__icon">
                        <img class="menu-golstats__item-menu__icon__previas" src="/images/menu/icn-previas@2x.png" />
                      </div>
                      <div class="menu-golstats__item-menu__title">
                        <span class="menu-golstats__item-menu__title__bold">Calendario</span>
                      </div>
                      <div class="menu-golstats__item-menu__indicador" v-if="optionMenuSelected == 'previasGS'">_</div>
                    </div>
                    <div class="menu-golstats__item-menu-separador">
                      <div class="menu-golstats__item-menu-separador__line"></div>
                    </div>
                    <div
                      class="menu-golstats__item-menu"
                      :class="!showLive ? 'item-menu-live' : ''"
                      v-if="liveGS"
                      @click="openLive"
                    >
                      <div class="menu-golstats__item-menu__icon">
                        <img
                          :class="
                            showLive
                              ? 'menu-golstats__item-menu__icon__live'
                              : 'menu-golstats__item-menu__icon__live-none'
                          "
                          src="/images/menu/icn_live.svg"
                        />
                      </div>
                      <div class="menu-golstats__item-menu__title">
                        <span class="menu-golstats__item-menu__title__bold">LIVE</span>
                      </div>
                      <div class="menu-golstats__item-menu__indicador" v-if="optionMenuSelected == 'liveGS'">_</div>
                    </div>
                  </div>
                </BCarouselItem>
                <BCarouselItem>
                  <div class="menu-golstats">
                    <div class="menu-golstats__item-menu" @click="scrollToElement('apostadorGS')">
                      <div class="menu-golstats__item-menu__icon">
                        <img
                          class="menu-golstats__item-menu__icon__apostadores"
                          src="/images/menu/icn-apostadores@2x.png"
                        />
                      </div>
                      <div class="menu-golstats__item-menu__title">
                        <span class="menu-golstats__item-menu__title__bold"
                          >Apostador<br />
                          <span class="picks-text" v-if="isPremiumUser">Picks</span
                          ><span v-else class="picks-text">Free Picks</span></span
                        >
                      </div>
                      <div class="menu-golstats__item-menu__indicador" v-if="optionMenuSelected == 'apostadorGS'">
                        _
                      </div>
                    </div>
                    <div class="menu-golstats__item-menu-separador">
                      <div class="menu-golstats__item-menu-separador__line"></div>
                    </div>
                    <div class="menu-golstats__item-menu" @click="scrollToElement('mejoresGS')">
                      <div class="menu-golstats__item-menu__icon">
                        <img
                          class="menu-golstats__item-menu__icon__jugadores"
                          src="/images/menu/icn-mejores-jugadores@2x.png"
                        />
                      </div>
                      <div class="menu-golstats__item-menu__title">
                        <span class="menu-golstats__item-menu__title__bold">Mejores</span><br />
                        Jugadores
                      </div>
                      <div class="menu-golstats__item-menu__indicador" v-if="optionMenuSelected == 'mejoresGS'">_</div>
                    </div>
                    <div class="menu-golstats__item-menu-separador">
                      <div class="menu-golstats__item-menu-separador__line"></div>
                    </div>
                    <div class="menu-golstats__item-menu" @click="scrollToElement('previasGS')">
                      <div class="menu-golstats__item-menu__icon">
                        <img class="menu-golstats__item-menu__icon__previas" src="/images/menu/icn-previas@2x.png" />
                      </div>
                      <div class="menu-golstats__item-menu__title">
                        <span class="menu-golstats__item-menu__title__bold">Calendario</span>
                      </div>
                      <div class="menu-golstats__item-menu__indicador" v-if="optionMenuSelected == 'previasGS'">_</div>
                    </div>
                    <div class="menu-golstats__item-menu-separador">
                      <div class="menu-golstats__item-menu-separador__line"></div>
                    </div>
                    <div
                      class="menu-golstats__item-menu"
                      :class="!showLive ? 'item-menu-live' : ''"
                      v-if="liveGS"
                      @click="openLive"
                    >
                      <div class="menu-golstats__item-menu__icon">
                        <img
                          :class="
                            showLive
                              ? 'menu-golstats__item-menu__icon__live'
                              : 'menu-golstats__item-menu__icon__live-none'
                          "
                          src="/images/menu/icn_live.svg"
                        />
                      </div>
                      <div class="menu-golstats__item-menu__title">
                        <span class="menu-golstats__item-menu__title__bold">LIVE</span>
                      </div>
                      <div class="menu-golstats__item-menu__indicador" v-if="optionMenuSelected == 'liveGS'">_</div>
                    </div>
                    <div class="menu-golstats__item-menu-separador">
                      <div class="menu-golstats__item-menu-separador__line"></div>
                    </div>
                    <div class="menu-golstats__item-menu" @click="scrollToElement('tablaGS')">
                      <div class="menu-golstats__item-menu__icon">
                        <img
                          class="menu-golstats__item-menu__icon__tabla"
                          src="/images/menu/icn-tabla-general@2x.png"
                        />
                      </div>
                      <div class="menu-golstats__item-menu__title">
                        Tabla <br /><span class="menu-golstats__item-menu__title__bold">General</span>
                      </div>
                      <div class="menu-golstats__item-menu__indicador" v-if="optionMenuSelected == 'tablaGS'">_</div>
                    </div>
                  </div>
                </BCarouselItem>
              </template>
              <template v-else>
                <template v-if="layoutType == 'bets'">
                  <BCarouselItem>
                    <div class="menu-golstats">
                      <div class="menu-golstats__item-menu" @click="scrollToElement('datosGS')">
                        <div class="menu-golstats__item-menu__icon">
                          <img class="menu-golstats__item-menu__icon__datos" src="/images/menu/icn-datos-gs@2x.png" />
                        </div>
                        <div class="menu-golstats__item-menu__title">
                          <span class="menu-golstats__item-menu__title__bold">Datos</span> <br />GolStats
                        </div>
                        <div class="menu-golstats__item-menu__indicador" v-if="optionMenuSelected == 'datosGS'">_</div>
                      </div>
                      <div class="menu-golstats__item-menu-separador">
                        <div class="menu-golstats__item-menu-separador__line"></div>
                      </div>
                      <div class="menu-golstats__item-menu" @click="scrollToElement('apostadorGS')">
                        <div class="menu-golstats__item-menu__icon">
                          <img
                            class="menu-golstats__item-menu__icon__apostadores"
                            src="/images/menu/icn-apostadores@2x.png"
                          />
                        </div>
                        <div class="menu-golstats__item-menu__title">
                          <span class="menu-golstats__item-menu__title__bold">Apostador</span>
                        </div>
                        <div class="menu-golstats__item-menu__indicador" v-if="optionMenuSelected == 'apostadorGS'">
                          _
                        </div>
                      </div>
                      <div class="menu-golstats__item-menu-separador">
                        <div class="menu-golstats__item-menu-separador__line"></div>
                      </div>
                      <div
                        class="menu-golstats__item-menu"
                        :class="!showLive ? 'item-menu-live' : ''"
                        v-if="liveGS"
                        @click="openLive"
                      >
                        <div class="menu-golstats__item-menu__icon">
                          <img
                            :class="
                              showLive
                                ? 'menu-golstats__item-menu__icon__live'
                                : 'menu-golstats__item-menu__icon__live-none'
                            "
                            src="/images/menu/icn_live.svg"
                          />
                        </div>
                        <div class="menu-golstats__item-menu__title">
                          <span class="menu-golstats__item-menu__title__bold">LIVE</span>
                        </div>
                        <div class="menu-golstats__item-menu__indicador" v-if="optionMenuSelected == 'liveGS'">_</div>
                      </div>
                    </div>
                  </BCarouselItem>
                </template>
                <template v-else>
                  <template v-if="layoutType == 'goltipster'">
                    <BCarouselItem>
                      <div class="menu-golstats">
                        <div class="menu-golstats__item-menu" @click="scrollToElement('apostadorGS')">
                          <div class="menu-golstats__item-menu__icon">
                            <img
                              class="menu-golstats__item-menu__icon__apostadores"
                              src="/images/menu/icn-apostadores@2x.png"
                            />
                          </div>
                          <div class="menu-golstats__item-menu__title">
                            <span class="menu-golstats__item-menu__title__bold"
                              >Apostador<br /><span class="picks-text" v-if="isPremiumUser">Picks</span
                              ><span v-else class="picks-text">Free Picks</span></span
                            >
                          </div>
                          <div class="menu-golstats__item-menu__indicador" v-if="optionMenuSelected == 'apostadorGS'">
                            _
                          </div>
                        </div>
                        <div class="menu-golstats__item-menu-separador">
                          <div class="menu-golstats__item-menu-separador__line"></div>
                        </div>
                        <div class="menu-golstats__item-menu" @click="scrollToElement('testimoniosGS')">
                          <div class="menu-golstats__item-menu__icon">
                            <img
                              class="menu-golstats__item-menu__icon__apostadores"
                              src="/images/menu/icn_testimonios@2x.png"
                            />
                          </div>
                          <div class="menu-golstats__item-menu__title">
                            <span class="menu-golstats__item-menu__title__bold">Testimonios</span>
                          </div>
                          <div class="menu-golstats__item-menu__indicador" v-if="optionMenuSelected == 'testimoniosGS'">
                            _
                          </div>
                        </div>
                        <div class="menu-golstats__item-menu-separador">
                          <div class="menu-golstats__item-menu-separador__line"></div>
                        </div>
                        <div class="menu-golstats__item-menu" @click="scrollToElement('datosGS')">
                          <div class="menu-golstats__item-menu__icon">
                            <img class="menu-golstats__item-menu__icon__datos" src="/images/menu/icn-datos-gs@2x.png" />
                          </div>
                          <div class="menu-golstats__item-menu__title">
                            <span class="menu-golstats__item-menu__title__bold">Datos</span> <br />GolStats
                          </div>
                          <div class="menu-golstats__item-menu__indicador" v-if="optionMenuSelected == 'datosGS'">
                            _
                          </div>
                        </div>
                        <div class="menu-golstats__item-menu-separador">
                          <div class="menu-golstats__item-menu-separador__line"></div>
                        </div>
                        <div class="menu-golstats__item-menu" @click="scrollToElement('mejoresGS')">
                          <div class="menu-golstats__item-menu__icon">
                            <img
                              class="menu-golstats__item-menu__icon__jugadores"
                              src="/images/menu/icn-mejores-jugadores@2x.png"
                            />
                          </div>
                          <div class="menu-golstats__item-menu__title">
                            <span class="menu-golstats__item-menu__title__bold">Mejores</span><br />
                            Jugadores
                          </div>
                          <div class="menu-golstats__item-menu__indicador" v-if="optionMenuSelected == 'mejoresGS'">
                            _
                          </div>
                        </div>
                        <div class="menu-golstats__item-menu-separador">
                          <div class="menu-golstats__item-menu-separador__line"></div>
                        </div>
                        <div class="menu-golstats__item-menu" @click="scrollToElement('previasGS')">
                          <div class="menu-golstats__item-menu__icon">
                            <img
                              class="menu-golstats__item-menu__icon__previas"
                              src="/images/menu/icn-previas@2x.png"
                            />
                          </div>
                          <div class="menu-golstats__item-menu__title">
                            <span class="menu-golstats__item-menu__title__bold">Calendario</span>
                          </div>
                          <div class="menu-golstats__item-menu__indicador" v-if="optionMenuSelected == 'previasGS'">
                            _
                          </div>
                        </div>
                        <div class="menu-golstats__item-menu-separador">
                          <div class="menu-golstats__item-menu-separador__line"></div>
                        </div>
                        <div
                          class="menu-golstats__item-menu"
                          :class="!showLive ? 'item-menu-live' : ''"
                          v-if="liveGS"
                          @click="openLive"
                        >
                          <div class="menu-golstats__item-menu__icon">
                            <img
                              :class="
                                showLive
                                  ? 'menu-golstats__item-menu__icon__live'
                                  : 'menu-golstats__item-menu__icon__live-none'
                              "
                              src="/images/menu/icn_live.svg"
                            />
                          </div>
                          <div class="menu-golstats__item-menu__title">
                            <span class="menu-golstats__item-menu__title__bold">LIVE</span>
                          </div>
                          <div class="menu-golstats__item-menu__indicador" v-if="optionMenuSelected == 'liveGS'">_</div>
                        </div>
                      </div>
                    </BCarouselItem>
                    <BCarouselItem>
                      <div class="menu-golstats">
                        <div class="menu-golstats__item-menu" @click="scrollToElement('testimoniosGS')">
                          <div class="menu-golstats__item-menu__icon">
                            <img
                              class="menu-golstats__item-menu__icon__apostadores"
                              src="/images/menu/icn_testimonios@2x.png"
                            />
                          </div>
                          <div class="menu-golstats__item-menu__title">
                            <span class="menu-golstats__item-menu__title__bold">Testimonios</span>
                          </div>
                          <div class="menu-golstats__item-menu__indicador" v-if="optionMenuSelected == 'testimoniosGS'">
                            _
                          </div>
                        </div>
                        <div class="menu-golstats__item-menu-separador">
                          <div class="menu-golstats__item-menu-separador__line"></div>
                        </div>
                        <div class="menu-golstats__item-menu" @click="scrollToElement('datosGS')">
                          <div class="menu-golstats__item-menu__icon">
                            <img class="menu-golstats__item-menu__icon__datos" src="/images/menu/icn-datos-gs@2x.png" />
                          </div>
                          <div class="menu-golstats__item-menu__title">
                            <span class="menu-golstats__item-menu__title__bold">Datos</span> <br />GolStats
                          </div>
                          <div class="menu-golstats__item-menu__indicador" v-if="optionMenuSelected == 'datosGS'">
                            _
                          </div>
                        </div>
                        <div class="menu-golstats__item-menu-separador">
                          <div class="menu-golstats__item-menu-separador__line"></div>
                        </div>
                        <div class="menu-golstats__item-menu" @click="scrollToElement('mejoresGS')">
                          <div class="menu-golstats__item-menu__icon">
                            <img
                              class="menu-golstats__item-menu__icon__jugadores"
                              src="/images/menu/icn-mejores-jugadores@2x.png"
                            />
                          </div>
                          <div class="menu-golstats__item-menu__title">
                            <span class="menu-golstats__item-menu__title__bold">Mejores</span><br />
                            Jugadores
                          </div>
                          <div class="menu-golstats__item-menu__indicador" v-if="optionMenuSelected == 'mejoresGS'">
                            _
                          </div>
                        </div>
                        <div class="menu-golstats__item-menu-separador">
                          <div class="menu-golstats__item-menu-separador__line"></div>
                        </div>
                        <div class="menu-golstats__item-menu" @click="scrollToElement('previasGS')">
                          <div class="menu-golstats__item-menu__icon">
                            <img
                              class="menu-golstats__item-menu__icon__previas"
                              src="/images/menu/icn-previas@2x.png"
                            />
                          </div>
                          <div class="menu-golstats__item-menu__title">
                            <span class="menu-golstats__item-menu__title__bold">Calendario</span>
                          </div>
                          <div class="menu-golstats__item-menu__indicador" v-if="optionMenuSelected == 'previasGS'">
                            _
                          </div>
                        </div>
                        <div class="menu-golstats__item-menu-separador">
                          <div class="menu-golstats__item-menu-separador__line"></div>
                        </div>
                        <div
                          class="menu-golstats__item-menu"
                          :class="!showLive ? 'item-menu-live' : ''"
                          v-if="liveGS"
                          @click="openLive"
                        >
                          <div class="menu-golstats__item-menu__icon">
                            <img
                              :class="
                                showLive
                                  ? 'menu-golstats__item-menu__icon__live'
                                  : 'menu-golstats__item-menu__icon__live-none'
                              "
                              src="/images/menu/icn_live.svg"
                            />
                          </div>
                          <div class="menu-golstats__item-menu__title">
                            <span class="menu-golstats__item-menu__title__bold">LIVE</span>
                          </div>
                          <div class="menu-golstats__item-menu__indicador" v-if="optionMenuSelected == 'liveGS'">_</div>
                        </div>
                        <div class="menu-golstats__item-menu-separador">
                          <div class="menu-golstats__item-menu-separador__line"></div>
                        </div>
                        <div class="menu-golstats__item-menu" @click="scrollToElement('tablaGS')">
                          <div class="menu-golstats__item-menu__icon">
                            <img
                              class="menu-golstats__item-menu__icon__tabla"
                              src="/images/menu/icn-tabla-general@2x.png"
                            />
                          </div>
                          <div class="menu-golstats__item-menu__title">
                            Tabla <br /><span class="menu-golstats__item-menu__title__bold">General</span>
                          </div>
                          <div class="menu-golstats__item-menu__indicador" v-if="optionMenuSelected == 'tablaGS'">
                            _
                          </div>
                        </div>
                      </div>
                    </BCarouselItem>
                  </template>
                  <template v-else>
                    <BCarouselItem>
                      <div class="menu-golstats">
                        <div class="menu-golstats__item-menu" @click="scrollToElement('datosGS')">
                          <div class="menu-golstats__item-menu__icon">
                            <img class="menu-golstats__item-menu__icon__datos" src="/images/menu/icn-datos-gs@2x.png" />
                          </div>
                          <div class="menu-golstats__item-menu__title">
                            <span class="menu-golstats__item-menu__title__bold">Datos</span> <br />GolStats
                          </div>
                          <div class="menu-golstats__item-menu__indicador" v-if="optionMenuSelected == 'datosGS'">
                            _
                          </div>
                        </div>
                        <div class="menu-golstats__item-menu-separador">
                          <div class="menu-golstats__item-menu-separador__line"></div>
                        </div>
                        <div class="menu-golstats__item-menu" @click="scrollToElement('apostadorGS')">
                          <div class="menu-golstats__item-menu__icon">
                            <img
                              class="menu-golstats__item-menu__icon__apostadores"
                              src="/images/menu/icn-apostadores@2x.png"
                            />
                          </div>
                          <div class="menu-golstats__item-menu__title">
                            <span class="menu-golstats__item-menu__title__bold">Apostador</span>
                          </div>
                          <div class="menu-golstats__item-menu__indicador" v-if="optionMenuSelected == 'apostadorGS'">
                            _
                          </div>
                        </div>
                        <div class="menu-golstats__item-menu-separador">
                          <div class="menu-golstats__item-menu-separador__line"></div>
                        </div>
                        <div class="menu-golstats__item-menu" @click="scrollToElement('mejoresGS')">
                          <div class="menu-golstats__item-menu__icon">
                            <img
                              class="menu-golstats__item-menu__icon__jugadores"
                              src="/images/menu/icn-mejores-jugadores@2x.png"
                            />
                          </div>
                          <div class="menu-golstats__item-menu__title">
                            <span class="menu-golstats__item-menu__title__bold">Mejores</span><br />
                            Jugadores
                          </div>
                          <div class="menu-golstats__item-menu__indicador" v-if="optionMenuSelected == 'mejoresGS'">
                            _
                          </div>
                        </div>
                        <div class="menu-golstats__item-menu-separador">
                          <div class="menu-golstats__item-menu-separador__line"></div>
                        </div>
                        <div class="menu-golstats__item-menu" @click="scrollToElement('previasGS')">
                          <div class="menu-golstats__item-menu__icon">
                            <img
                              class="menu-golstats__item-menu__icon__previas"
                              src="/images/menu/icn-previas@2x.png"
                            />
                          </div>
                          <div class="menu-golstats__item-menu__title">
                            <span class="menu-golstats__item-menu__title__bold">Calendario</span>
                          </div>
                          <div class="menu-golstats__item-menu__indicador" v-if="optionMenuSelected == 'previasGS'">
                            _
                          </div>
                        </div>
                        <div class="menu-golstats__item-menu-separador">
                          <div class="menu-golstats__item-menu-separador__line"></div>
                        </div>
                        <div
                          class="menu-golstats__item-menu"
                          :class="!showLive ? 'item-menu-live' : ''"
                          v-if="liveGS"
                          @click="openLive"
                        >
                          <div class="menu-golstats__item-menu__icon">
                            <img
                              :class="
                                showLive
                                  ? 'menu-golstats__item-menu__icon__live'
                                  : 'menu-golstats__item-menu__icon__live-none'
                              "
                              src="/images/menu/icn_live.svg"
                            />
                          </div>
                          <div class="menu-golstats__item-menu__title">
                            <span class="menu-golstats__item-menu__title__bold">LIVE</span>
                          </div>
                          <div class="menu-golstats__item-menu__indicador" v-if="optionMenuSelected == 'liveGS'">_</div>
                        </div>
                      </div>
                    </BCarouselItem>
                    <BCarouselItem>
                      <div class="menu-golstats">
                        <div class="menu-golstats__item-menu" @click="scrollToElement('apostadorGS')">
                          <div class="menu-golstats__item-menu__icon">
                            <img
                              class="menu-golstats__item-menu__icon__apostadores"
                              src="/images/menu/icn-apostadores@2x.png"
                            />
                          </div>
                          <div class="menu-golstats__item-menu__title">
                            <span class="menu-golstats__item-menu__title__bold">Apostador</span>
                          </div>
                          <div class="menu-golstats__item-menu__indicador" v-if="optionMenuSelected == 'apostadorGS'">
                            _
                          </div>
                        </div>
                        <div class="menu-golstats__item-menu-separador">
                          <div class="menu-golstats__item-menu-separador__line"></div>
                        </div>
                        <div class="menu-golstats__item-menu" @click="scrollToElement('mejoresGS')">
                          <div class="menu-golstats__item-menu__icon">
                            <img
                              class="menu-golstats__item-menu__icon__jugadores"
                              src="/images/menu/icn-mejores-jugadores@2x.png"
                            />
                          </div>
                          <div class="menu-golstats__item-menu__title">
                            <span class="menu-golstats__item-menu__title__bold">Mejores</span><br />
                            Jugadores
                          </div>
                          <div class="menu-golstats__item-menu__indicador" v-if="optionMenuSelected == 'mejoresGS'">
                            _
                          </div>
                        </div>
                        <div class="menu-golstats__item-menu-separador">
                          <div class="menu-golstats__item-menu-separador__line"></div>
                        </div>
                        <div class="menu-golstats__item-menu" @click="scrollToElement('previasGS')">
                          <div class="menu-golstats__item-menu__icon">
                            <img
                              class="menu-golstats__item-menu__icon__previas"
                              src="/images/menu/icn-previas@2x.png"
                            />
                          </div>
                          <div class="menu-golstats__item-menu__title">
                            <span class="menu-golstats__item-menu__title__bold">Calendario</span>
                          </div>
                          <div class="menu-golstats__item-menu__indicador" v-if="optionMenuSelected == 'previasGS'">
                            _
                          </div>
                        </div>
                        <div class="menu-golstats__item-menu-separador">
                          <div class="menu-golstats__item-menu-separador__line"></div>
                        </div>
                        <div
                          class="menu-golstats__item-menu"
                          :class="!showLive ? 'item-menu-live' : ''"
                          v-if="liveGS"
                          @click="openLive"
                        >
                          <div class="menu-golstats__item-menu__icon">
                            <img
                              :class="
                                showLive
                                  ? 'menu-golstats__item-menu__icon__live'
                                  : 'menu-golstats__item-menu__icon__live-none'
                              "
                              src="/images/menu/icn_live.svg"
                            />
                          </div>
                          <div class="menu-golstats__item-menu__title">
                            <span class="menu-golstats__item-menu__title__bold">LIVE</span>
                          </div>
                          <div class="menu-golstats__item-menu__indicador" v-if="optionMenuSelected == 'liveGS'">_</div>
                        </div>
                        <div class="menu-golstats__item-menu-separador">
                          <div class="menu-golstats__item-menu-separador__line"></div>
                        </div>
                        <div class="menu-golstats__item-menu" @click="scrollToElement('tablaGS')">
                          <div class="menu-golstats__item-menu__icon">
                            <img
                              class="menu-golstats__item-menu__icon__tabla"
                              src="/images/menu/icn-tabla-general@2x.png"
                            />
                          </div>
                          <div class="menu-golstats__item-menu__title">
                            Tabla <br /><span class="menu-golstats__item-menu__title__bold">General</span>
                          </div>
                          <div class="menu-golstats__item-menu__indicador" v-if="optionMenuSelected == 'tablaGS'">
                            _
                          </div>
                        </div>
                      </div>
                    </BCarouselItem>
                  </template>
                </template>
              </template>
            </BCarousel>
          </div>
        </div>
      </div>
    </div>
    <Lives :show="showModal" @close="closeModal" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import { BCarousel, BCarouselItem } from 'buefy/dist/esm/carousel';
import Lives from '@/views/Lives';
export default {
  name: 'MenuGolStats',
  components: { BCarousel, BCarouselItem, Lives },
  props: {
    sectionScroll: {
      type: String,
      required: false,
    },
    datosGS: {
      type: Boolean,
      default: true,
    },
    linkApostador: {
      type: Boolean,
      default: false,
    },
    videosGS: {
      type: Boolean,
      default: true,
    },
    testimoniosGS: {
      type: Boolean,
      default: false,
    },
    mejoresGS: {
      type: Boolean,
      default: true,
    },
    apostadorGS: {
      type: Boolean,
      default: true,
    },
    equiposGS: {
      type: Boolean,
      default: true,
    },
    previasGS: {
      type: Boolean,
      default: true,
    },
    tablaGS: {
      type: Boolean,
      default: true,
    },
    liveGS: {
      type: Boolean,
      default: true,
    },
    layoutType: {
      type: String,
      required: false,
    },
  },
  watch: {
    sectionScroll: {
      immediate: true,
      handler: function(newVal) {
        this.optionMenuSelected = newVal;
      },
    },
  },
  computed: {
    ...mapGetters('loginGeneral', ['getPremiumAccount', 'isLoggedIn']),
    isPremiumUser() {
      return this.isLoggedIn && this.getPremiumAccount;
    },
  },
  data() {
    return {
      url: 'https://qobetc4ze3.execute-api.us-east-2.amazonaws.com/prod/games/live',
      showLive: false,
      showModal: false,
      divscrolltop: 0,
      fixedOrderPanel: false,
      fixedMovil: 0,
      optionMenuSelected: 'datosGS',
    };
  },
  async created() {
    await this.getLivesData();
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.fixedMovil = 2;
    } else {
      this.fixedMovil = 1;
    }
  },
  methods: {
    async getLivesData() {
      try {
        const { data: games } = await axios
          .get(this.url)
          .then(response => response)
          .catch(e => {
            if (this.retries > 0) {
              this.retries = this.retries - 1;
              return this.getLivesData();
            }
            return e;
          });
        if (games) {
          this.showLive = true;
        } else {
          this.showLive = false;
        }
      } catch (e) {
        throw new Error(e);
      }
    },
    openLive() {
      if (this.showLive) {
        this.showModal = true;
      }
    },
    closeModal() {
      this.showModal = false;
    },
    scrollToElement(item) {
      this.$emit('scrollItem', item);
      if (!(this.linkApostador && item == 'apostadorGS')) {
        this.optionMenuSelected = item;
      }
    },
    handleScroll(event) {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        this.fixedMovil = 2;
      } else {
        this.fixedMovil = 1;
      }
      if (event.target.scrollTop > 60) {
        this.fixedOrderPanel = true;
      } else {
        this.fixedOrderPanel = false;
      }
    },
  },

  beforeMount() {
    window.addEventListener('scroll', this.handleScroll, true);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>
<style>
.menu-golstats .carousel .carousel-indicator .indicator-item .indicator-style {
  width: 17px;
  height: 5px;
  border-radius: 2px;
  background-color: #d8d8d8;
  border: none;
  /* display: none; */
}
.menu-golstats .carousel .carousel-indicator .indicator-item.is-active .indicator-style {
  background: #7ed321;
}
.menu-golstats .carousel .carousel-indicator.is-inside.is-bottom {
  bottom: 12px;
}
.fixed {
  position: absolute !important;
  width: 100% !important;
  height: 2px !important;
  z-index: 45 !important;
  top: 0px !important;
  padding: 0px !important;
}
html > body {
  padding-top: 0 !important;
}
.is-fullheight-with-navbar-and-footer,
.is-fullheight-with-navbar-and-footer-mobile {
  height: 100%;
}
</style>
<style lang="scss" scoped>
.picks-text {
  font-family: 'Circular-Std-Medium';
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.95px;
  text-align: center;
  color: #22a4dd;
  @media screen and (max-width: 634px) {
    font-size: 13px;
  }
  @media screen and (max-width: 360px) {
    font-size: 11px;
  }
}
.menu-golstats-pc-indicator {
  display: inline-block;
  @media screen and (max-width: 634px) {
    display: none;
  }
}
.menu-golstats-movil-indicator {
  display: none;
  @media screen and (max-width: 634px) {
    display: inline-block;
  }
}
.container-wrapper-menu-golstats {
  width: calc(100% - 17px);
  height: 124px;

  box-shadow: 0 15px 28px 0 rgba(64, 73, 85, 0.5);
  background-color: #fff;
  @media screen and (max-width: 634px) {
    height: 114px;
  }
}
.item-menu-live:hover {
  opacity: 1 !important;
  cursor: default !important;
}
.container-wrapper-menu-golstats-movil {
  width: 100%;
  height: 124px;
  box-shadow: 0 15px 28px 0 rgba(64, 73, 85, 0.5);
  background-color: #fff;
  @media screen and (max-width: 634px) {
    height: 114px;
  }
}
.container-wrapper-menu-golstats-flag {
  width: calc(100% - 17px);
  height: 124px;
  background-color: #fff;
  @media screen and (max-width: 634px) {
    height: 114px;
  }
}
.container-wrapper-menu-golstats-movil-flag {
  width: 100%;
  height: 124px;
  background-color: #fff;
  @media screen and (max-width: 634px) {
    height: 114px;
  }
}
.menu-golstats {
  width: 993px;
  height: 124px;
  margin: 0 auto;
  top: 0;
  display: inline;
  z-index: 3;
  background: white;
  // background: red;
  @media screen and (max-width: 994px) {
    width: 904px;
    // background: gold;
  }
  @media screen and (max-width: 904px) {
    width: 814px;
    // background: rgb(17, 172, 120);
  }
  @media screen and (max-width: 814px) {
    width: 724px;
    // background: rgb(163, 61, 14);
  }
  @media screen and (max-width: 724px) {
    width: 634px;
    // background: rgb(3, 2, 3);
  }
  @media screen and (max-width: 634px) {
    width: 544px;
    height: 114px;
    // background: rgb(78, 2, 78);
  }
  @media screen and (max-width: 544px) {
    width: 504px;
    // background: rgb(235, 16, 16);
  }
  @media screen and (max-width: 504px) {
    width: 454px;
    // background: rgb(16, 235, 53);
  }
  @media screen and (max-width: 454px) {
    width: 414px;
    // background: rgb(60, 105, 2);
  }
  @media screen and (max-width: 414px) {
    width: 384px;
    // background: rgb(109, 118, 146);
  }
  @media screen and (max-width: 380px) {
    width: 354px;
    // background: rgb(1, 66, 12);
  }
  @media screen and (max-width: 354px) {
    width: 274px;
    // background: rgb(143, 1, 72);
  }
  @media screen and (max-width: 354px) {
    width: 324px;
    // background: rgb(161, 79, 1);
  }
  @media screen and (max-width: 324px) {
    width: 298px;
    // background: rgb(1, 4, 14);
  }

  &__item-menu:hover {
    opacity: 0.7;
    cursor: pointer;
  }
  &__item-menu {
    width: 90px;
    height: 108px;
    // background: royalblue;
    float: left;
    margin-top: 8px;
    @media screen and (max-width: 994px) {
    }
    @media screen and (max-width: 904px) {
    }
    @media screen and (max-width: 814px) {
      width: 80px;
    }
    @media screen and (max-width: 724px) {
      width: 68px;
    }
    @media screen and (max-width: 634px) {
      width: 75px;
      margin-top: 6px;
      height: 88px;
    }
    @media screen and (max-width: 544px) {
    }
    @media screen and (max-width: 504px) {
      width: 66px;
    }
    @media screen and (max-width: 454px) {
      width: 63px;
    }
    @media screen and (max-width: 414px) {
      width: 60px;
    }
    @media screen and (max-width: 383px) {
      width: 56px;
    }
    @media screen and (max-width: 354px) {
      width: 50px;
    }
    @media screen and (max-width: 324px) {
      width: 47px;
    }
    &__icon {
      width: 90px;
      height: 55px;
      float: left;
      // background: limegreen;
      @media screen and (max-width: 994px) {
      }
      @media screen and (max-width: 904px) {
      }
      @media screen and (max-width: 814px) {
        width: 80px;
      }
      @media screen and (max-width: 724px) {
        width: 68px;
      }
      @media screen and (max-width: 634px) {
        width: 75px;
        height: 40px;
      }
      @media screen and (max-width: 544px) {
      }
      @media screen and (max-width: 504px) {
        width: 66px;
      }
      @media screen and (max-width: 454px) {
        width: 63px;
      }
      @media screen and (max-width: 414px) {
        width: 60px;
      }
      @media screen and (max-width: 383px) {
        width: 56px;
      }
      @media screen and (max-width: 354px) {
        width: 51px;
      }
      @media screen and (max-width: 324px) {
        width: 47px;
      }
      &__datos {
        width: 35px;
        margin-top: 9px;
        @media screen and (max-width: 634px) {
          width: 34px;
          margin-top: 6px;
        }
        @media screen and (max-width: 544px) {
          width: 29px;
          margin-top: 7px;
        }
      }
      &__videos {
        width: 35px;
        margin-top: 11px;
        @media screen and (max-width: 634px) {
          width: 33px;
          margin-top: 4px;
        }
        @media screen and (max-width: 544px) {
          width: 29px;
          margin-top: 6px;
        }
      }
      &__jugadores {
        width: 37px;
        margin-top: 11px;
        @media screen and (max-width: 634px) {
          width: 30px;
          margin-top: 7px;
        }
        @media screen and (max-width: 544px) {
          width: 28px;
          margin-top: 7px;
        }
      }
      &__apostadores {
        width: 37px;
        margin-top: 11px;
        @media screen and (max-width: 634px) {
          width: 33px;
          margin-top: 4px;
        }
        @media screen and (max-width: 544px) {
          width: 29px;
          margin-top: 6px;
        }
      }
      &__equipos {
        width: 33px;
        margin-top: 11px;
        @media screen and (max-width: 634px) {
          width: 28px;
          margin-top: 6px;
        }
        @media screen and (max-width: 544px) {
          width: 26px;
          margin-top: 7px;
        }
      }
      &__previas {
        width: 71px;
        margin-top: 16px;
        @media screen and (max-width: 634px) {
          width: 69px;
          margin-top: 9px;
        }
        @media screen and (max-width: 544px) {
          width: 62px;
          margin-top: 10px;
        }
      }
      &__tabla {
        width: 37px;
        margin-top: 11px;
        @media screen and (max-width: 634px) {
          width: 32px;
          margin-top: 4px;
        }
        @media screen and (max-width: 544px) {
          width: 30px;
          margin-top: 5px;
        }
      }
      &__live-none {
        width: 51px;
        margin-top: 3px;
        filter: invert(5%) sepia(6%) saturate(38%) hue-rotate(169deg) brightness(82%) contrast(91%);
        cursor: default;
        @media screen and (max-width: 634px) {
          width: 46px;
          margin-top: 0px;
        }
        @media screen and (max-width: 544px) {
          width: 43px;
          margin-top: 1px;
        }
      }
      &__live {
        width: 51px;
        margin-top: 3px;
        @media screen and (max-width: 634px) {
          width: 46px;
          margin-top: 0px;
        }
        @media screen and (max-width: 544px) {
          width: 43px;
          margin-top: 1px;
        }
      }
    }
    &__title {
      width: 90px;
      height: 43px;
      float: left;
      // background: magenta;
      font-family: 'Circular-Std-Book';
      font-size: 15px;
      padding-top: 5px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.06;
      letter-spacing: -0.6px;
      text-align: center;
      color: #494949;
      &__bold {
        font-family: 'Circular-Std-Bold';
      }
      @media screen and (max-width: 994px) {
      }
      @media screen and (max-width: 904px) {
      }
      @media screen and (max-width: 814px) {
        width: 80px;
        font-size: 14px;
      }
      @media screen and (max-width: 724px) {
        width: 68px;
        font-size: 13px;
      }
      @media screen and (max-width: 634px) {
        width: 75px;
        height: 36px;
        font-size: 12px;
      }
      @media screen and (max-width: 544px) {
      }
      @media screen and (max-width: 504px) {
        width: 66px;
      }
      @media screen and (max-width: 454px) {
        width: 63px;
      }
      @media screen and (max-width: 414px) {
        width: 60px;
      }
      @media screen and (max-width: 380px) {
        width: 56px;
        font-size: 11px;
      }
      @media screen and (max-width: 354px) {
        width: 51px;
        font-size: 10px;
      }
      @media screen and (max-width: 324px) {
        width: 47px;
      }
    }
    &__indicador {
      width: 90px;
      height: 9px;
      float: left;
      margin-top: -14px;
      font-family: 'Circular-Std-Bold';
      color: #89da69;
      font-size: 38px;
      line-height: 9px;
      @media screen and (max-width: 994px) {
      }
      @media screen and (max-width: 904px) {
      }
      @media screen and (max-width: 814px) {
        width: 80px;
      }
      @media screen and (max-width: 724px) {
        width: 68px;
      }
      @media screen and (max-width: 634px) {
        width: 75px;
      }
      @media screen and (max-width: 544px) {
      }
      @media screen and (max-width: 504px) {
        width: 66px;
      }
      @media screen and (max-width: 454px) {
        width: 63px;
      }
      @media screen and (max-width: 414px) {
        width: 60px;
      }
      @media screen and (max-width: 380px) {
        width: 56px;
      }
      @media screen and (max-width: 354px) {
        width: 51px;
      }
      @media screen and (max-width: 324px) {
        width: 47px;
      }
    }
  }
  &__item-menu-separador {
    width: 39px;
    height: 108px;
    margin-top: 8px;
    float: left;
    font-size: 16px;
    color: #d8d8d8;
    font-family: 'Circular-Std-Bold';
    padding-top: 15px;
    // background: yellowgreen;
    @media screen and (max-width: 994px) {
      width: 26.25px;
    }
    @media screen and (max-width: 904px) {
      width: 13.25px;
    }
    @media screen and (max-width: 814px) {
      width: 12px;
    }
    @media screen and (max-width: 724px) {
      width: 12.85px;
    }
    @media screen and (max-width: 634px) {
      width: 18.51px;
      margin-top: 6px;
      height: 88px;
    }
    @media screen and (max-width: 544px) {
      width: 10.5px;
    }
    @media screen and (max-width: 504px) {
      width: 11.5px;
    }
    @media screen and (max-width: 454px) {
      width: 7px;
    }
    @media screen and (max-width: 414px) {
      width: 4.5px;
    }
    @media screen and (max-width: 380px) {
      width: 3.45px;
    }
    @media screen and (max-width: 354px) {
      width: 3.45px;
    }
    @media screen and (max-width: 324px) {
      width: 3px;
    }
    &__line {
      width: 2px;
      height: 25px;
      border-left: 1px dotted #d8d8d8;
      margin: 0 auto;
      @media screen and (max-width: 634px) {
        height: 20px;
      }
    }
  }
}
</style>
